<template>
  <v-alert
    border="left"
    dense
    dismissible
    icon="mdi-shield-alert"
    prominent
    text
    type="info"
  >
    In order to protect your privacy, we <b>do not recommend</b> including
    clearly identifiable human faces or other objects that are easily
    identifiable to individuals or the surrounding environment in the photos.
  </v-alert>
</template>

<script>
export default {};
</script>

<style></style>
