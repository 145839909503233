<template>
  <v-footer class="justify-center grey--text" padless>
    <v-icon>mdi-lock-check</v-icon>Transaction secured by Strategic Explorations.
  </v-footer>
</template>

<script>
export default {};
</script>

<style></style>
